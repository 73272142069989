//services
import { createPrintFileAnchor } from "@/services/dom/domService";
import { saveFile } from "@/services/dom/fileSystemAccessService";

//model
import { extensionName } from "@/model/record/fileModel";
import { headerText } from "@/model/common/dataTable/dataTableConst";

//mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";

//utils
import {isBlob, isValidDate} from "@/utils";
import { getWordBlob } from "@/utils/htmlDocx";
import {
  getNewWorkbook,
  jsonToSheet,
  populateWorkbook,
  writeWorkbook
} from "@/utils/xlsx";
import { createJsPdf, generatePdfTable } from "@/utils/jsPdf";
import { moduleNames } from "@/model/solution/moduleModel";
import { downloadReportType, getTextValue } from "@/model/report/reportModel";
import { iconReport } from "@/design/icon/iconConst";
import moment from "moment";

export const reportableMixin = {
  mixins: [dialogOutlineMixin],
  components: {
    ReportingCard: () => import("@/components/shared/core/report/ReportingCard")
  },
  data() {
    return {
      moduleNames: moduleNames,
      downloadReportType: downloadReportType,
      iconReport: iconReport,
      //Table Data
      exportTitle: "report",
      exportAction: "Export",
      printAction: "Print"
    };
  },
  methods: {
    /**
     * Event on Report Download
     * @param {asPrintPdf: number, asExcel: number, asPdf: number, asWord: number} downloadType
     * @return {Promise<void>}
     */
    async onReportDownload(downloadType) {
      try {
        switch (downloadType) {
          case downloadReportType.asPdf:
            await this.downloadPdf();
            break;

          case downloadReportType.asExcel:
            await this.downloadExcel();
            break;

          case downloadReportType.asWord:
            await this.downloadWord();
            break;

          case downloadReportType.asPrintPdf:
            await this.printReport();
            break;

          default:
            await this.downloadPdf();
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Map Header Row to corresponding Data Row
     * @return {*}
     */
    mapHeaderToValues() {
      try {
        const rows =
          typeof this.sortedItems === "function"
            ? this.sortedItems()
            : this.items;

        const tableTitle =
          this.reportModule === moduleNames.Projects ? "Project" : "";

        return rows.map(item => {
          const rowData = {};
          this.tableHeaders.forEach(header => {
            const textValue = getTextValue[`${tableTitle}${header.text}`];
            rowData[header.text] = textValue
              ? textValue(item[header.value])
              : item[header.value] ?? this.getFieldValue(item, header.value);
          });
          return rowData;
        });
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Get Proper Row Data for Generating Tables
     * Expand on Demand
     * @return {{taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}|*|*[]|*[]}
     */
    getRowData() {
      if (this.reportModule === moduleNames.Projects)
        return this.items[0]?.userTasks ?? [];
    },

    /**
     * Get Proper Header Data for Generating Tables
     * Expand on Demand
     * @return {any[]|*[]}
     */
    getHeaderData() {
      if (this.reportModule === moduleNames.Projects)
        return this.projectTaskHeaders ?? [];
    },

    /**
     * Get Header Text for Child Table
     * @return {string}
     */
    getChildTableText() {
      if (this.reportModule === moduleNames.Projects) return "Project Task";
    },

    /**
     * Map Child Table (Second) Header Row to corresponding Data Row
     *  @return {{}[]}
     */
    mapChildHeaderToValues() {
      try {
        const childTableTitle =
          this.reportModule === moduleNames.Projects ? "Task" : "";
        const rows = this.getRowData();
        const headers = this.getHeaderData();
        return rows.map(item => {
          const rowData = {};
          headers.forEach(header => {
            const textValue = getTextValue[`${childTableTitle}${header.text}`];
            rowData[header.text] = textValue
              ? textValue(item[header.value])
              : item[header.value] ?? this.getFieldValue(item, header.value);
          });
          return rowData;
        });
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Get Field Value for corresponding header
     * @param item - Record
     * @param name - Field Value
     * @return {*}
     */
    getFieldValue(item, name) {
      try {
        const propertyNames = name
          .split(/[[\].]/)
          .filter(propertyName => propertyName !== ""); // ["searchField", "0", "value"]

        // Use the property names to access the corresponding nested property in the object
        // Use the property names to access the corresponding nested property in the object
        let nestedObj = item;
        for (const propertyName of propertyNames) {
          // eslint-disable-next-line no-prototype-builtins
          if (nestedObj.hasOwnProperty(propertyName)) {
            nestedObj = nestedObj[propertyName];
          } else {
            nestedObj = "";
            break;
          }
        }
        return nestedObj;
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Delete Row Data/Item
     * @param item
     */
    onDelete(item) {
      try {
        const index = this.items.findIndex(
          record =>
            (record?.name ?? record?.projectName) ===
            (item?.name ?? item.projectName)
        );
        this.items.splice(index, 1);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Print Report
     */
    async printReport() {
      try {
        const blobPdf = await this.generatePdfBlob();
        createPrintFileAnchor(blobPdf);
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.printAction, e),
          true
        );
      }
    },

    /**
     * Download Report as Pdf
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async downloadPdf() {
      try {
        const blobPdf = await this.generatePdfBlob();
        await this.prepareSaveFilePicker(blobPdf, extensionName.pdf);
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.exportAction, e),
          true
        );
      }
    },

    /**
     * Download Report as Excel
     */
    async downloadExcel() {
      try {
        const data = this.mapHeaderToValues();
        const exportableData = this.getExportableData(data);
        console.log(
          `${this.$options.name} downloadExcel() exportableData: `,
          exportableData
        );

        const worksheet = jsonToSheet(exportableData);
        const workbook = getNewWorkbook();
        populateWorkbook(workbook, worksheet, this.reportModule);

        if (this.isDetailedReport ?? false) {
          const childData = this.mapChildHeaderToValues();
          const childExportableData = this.getExportableData(childData);
          const childWorksheet = jsonToSheet(childExportableData);
          populateWorkbook(
            workbook,
            childWorksheet,
            `${this.reportModule} Task `
          );
        }

        const file = writeWorkbook(workbook, extensionName.excelX);
        const blobExcel = new Blob([file], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        await this.prepareSaveFilePicker(blobExcel, extensionName.excelX);
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.exportAction, e),
          true
        );
      }
    },

    /**
     * Generate Pdf Blob for export Pdf/ Print
     * @return {Blob} blob
     */
    generatePdfBlob() {
      try {
        const currentDate = this.currentDate;
        const doc = createJsPdf();

        // Export main table
        const exportableData = this.getExportableData(this.mapHeaderToValues());
        const mainTableData = this.generateTableData(exportableData);
        generatePdfTable(
          doc,
          mainTableData.tableData,
          mainTableData.headers,
          currentDate
        );

        // Export detailed report (Child Table - (if applicable))
        if (this.isDetailedReport) {
          const exportableData = this.getExportableData(
            this.mapChildHeaderToValues()
          );
          const detailedTableData = this.generateTableData(exportableData);
          const text = this.getChildTableText();
          doc.setFontSize(14);
          doc.text(text, doc.internal.pageSize.width / 2, 42, {
            align: "center"
          });

          doc.autoTable({
            head: [detailedTableData.headers],
            body: detailedTableData.tableData,
            startY: 50,
            styles: { fontSize: 8 }
          });
        }

        const blobPdf = new Blob([doc.output()], { type: "application/pdf" });
        console.log(
          `${this.$options.name} generatePdfBlob() isBlob: `,
          isBlob(blobPdf)
        );

        return blobPdf;
      } catch (e) {
        console.error(e);
        throw new Error(e);
      }
    },

    /**
     * Generate data for a table from the given JSON array
     * @param {Array} jsonArr
     * @returns {Object} tableData and headers
     */
    generateTableData(jsonArr) {
      const tableData = [];
      const headers = Object.keys(jsonArr[0]);

      jsonArr.forEach(data => {
        const row = headers.map(header => data[header]);
        tableData.push(row);
      });

      return { tableData, headers };
    },

    /**
     * Get Exportable Data from Array
     * used to remove Action Header
     * @param {Array}data
     * @return {*}
     */
    getExportableData(data) {
      const keyToRemove = headerText.actions;
      const result = data.map(obj => {
        const newObj = { ...obj };
        delete newObj[keyToRemove];
        return newObj;
      });
      if (!result.length) throw new Error("No Rows in the table");
      return result;
    },

    /**
     * Download Report as Word Document
     * @return {Promise<void>}
     */
    async downloadWord() {
      try {
        const data = this.mapHeaderToValues();
        const exportableData = this.getExportableData(data);
        let wordContent = document.createElement("div");

        const table = this.createTable(exportableData);
        wordContent.appendChild(table);

        if (this.isDetailedReport) {
          const detailedData = this.mapChildHeaderToValues();
          const exportableData = this.getExportableData(detailedData);

          const childTable = this.createTable(exportableData);
          const childHeading = document.createElement("h4");
          childHeading.textContent = "Project Tasks";
          childHeading.style.textAlign = "center";
          wordContent.appendChild(childHeading);
          wordContent.appendChild(childTable);
        }

        const blobWord = getWordBlob(wordContent.innerHTML);
        const extension = extensionName.wordX;
        await this.prepareSaveFilePicker(blobWord, extension);
      } catch (e) {
        console.error(e);
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError(this.exportAction, e),
          true
        );
      }
    },

    /**
     * Create a table element from exportable data
     * @param {Array} exportableData
     * @returns {HTMLTableElement}
     */
    createTable(exportableData) {
      const table = document.createElement("table");

      // Add header row
      const headerRow = table.insertRow();
      for (let key in exportableData[0]) {
        const th = document.createElement("th");
        th.textContent = key;
        headerRow.appendChild(th);
      }

      // Add data rows
      for (let item of exportableData) {
        const row = table.insertRow();
        for (let key in item) {
          const cell = row.insertCell();
          cell.textContent = item[key];
        }
      }

      return table;
    },

    /**
     * Prepare Save File Picker
     * @param {Blob} blob
     * @param {String} extensionName
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async prepareSaveFilePicker(blob, extensionName) {
      await saveFile(blob, this.exportTitle, extensionName);
    },

    /**
     * Custom Sort Table Items
     * Currently used to correct sort by date behaviour
     * @param {Object} items - List Items
     * @param {Array} sortBy - Selected Headers
     * @param {Boolean} sortDesc
     * @return {*}
     */
    customSort(items, sortBy, sortDesc) {
      return items.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {
          const sortByField = sortBy[i];
          const sortDirection = sortDesc[i] ? -1 : 1;

          // Check if sortByField is a date type/ is valid Date
          if (isValidDate(a[sortByField])) {
            const dateA = moment(a[sortByField], "MMM D, YYYY");
            const dateB = moment(b[sortByField], "MMM D, YYYY");
            if (dateA.isBefore(dateB)) return -1 * sortDirection;
            if (dateA.isAfter(dateB)) return 1 * sortDirection;
          } else {
            const valueA = (a[sortByField] || "").toString().toLowerCase();
            const valueB = (b[sortByField] || "").toString().toLowerCase();
            if (valueA < valueB) return -1 * sortDirection;
            if (valueA > valueB) return 1 * sortDirection;
          }
        }
        return 0; // Return 0 if all sorting criteria are equal
      });
    }
  }
};
