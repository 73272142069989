/**
 * Download Type of Report
 * @type {Readonly<{asPrintPdf: number, asExcel: number, asPdf: number, asWord: number}>}
 */
import {
  findTaskPriority,
  findTaskState
} from "@/model/workflow/task/taskModel";
import { findProjectState } from "@/model/workflow/project/projectModel";

/**
 * Get Download Report Type
 * @type {Readonly<{asPrintPdf: number, asExcel: number, asPdf: number, asWord: number}>}
 */
const downloadReportType = Object.freeze({
  asPdf: 0,
  asWord: 1,
  asExcel: 2,
  asPrintPdf: 3
});

/**
 * Get Text Value, Used if conversion required from property id to property text/name
 * @type {{ProjectStatus: (function(*=)), ProjectPriority: (function(*=)), TaskPriority: (function(*=)), TaskStatus: (function(*=))}}
 */
const getTextValue = {
  ProjectStatus: status => findProjectState(status)?.name ?? "",
  ProjectPriority: priority => findTaskPriority(priority)?.text ?? "",
  TaskPriority: priority => findTaskPriority(priority)?.text ?? "",
  TaskStatus: status => findTaskState(status)?.name ?? ""
  // Other header text to get Text Value mappings can be added here
};

export { downloadReportType, getTextValue };
