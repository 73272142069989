// The SheetJS Community Edition offers battle-tested open-source solutions for extracting useful data from almost any complex spreadsheet and generating new spreadsheets that will work with legacy and modern software alike.
// https://www.npmjs.com/package/xlsx

import { utils, write } from "xlsx";

/**
 * Converts Json Data to worksheet
 * @param {Array }data
 * @return {WorkSheet}
 */
const jsonToSheet = data => {
  return utils.json_to_sheet(data);
};

/**
 * Creates a new workbook
 * @return {WorkBook}
 */
const getNewWorkbook = () => {
  return utils.book_new();
};

/**
 * Append a worksheet to a workbook
 * @param {WorkBook} workbook
 * @param {WorkSheet} worksheet
 * @param {string} title
 */
function populateWorkbook(workbook, worksheet, title) {
  utils.book_append_sheet(workbook, worksheet, title);
}

/**
 * Attempts to write the workbook data
 * @param {WorkBook} workbook
 * @param {string} extension
 * @return {File} file
 */
const writeWorkbook = (workbook, extension) => {
  return write(workbook, {
    bookType: extension,
    type: "array"
  });
};

export { jsonToSheet, getNewWorkbook, populateWorkbook, writeWorkbook };
