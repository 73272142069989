// This is a very small library that is capable of converting HTML documents to DOCX format that is used by Microsoft Word 2007 and onward.
// This library should work on any modern browser that supports Blobs (either natively or via Blob.js).

// https://www.npmjs.com/package/html-docx-js

import htmlDocx from "html-docx-js/dist/html-docx";

/**
 * Creates Word Document Blob by provided Html
 * @param {String} html - htmlDocument as string
 * @return {Blob}
 */
const getWordBlob = html => {
  return htmlDocx.asBlob(html);
};

/**
 * Creates Word Document Blob by provided Html and Options
 * @param {HTMLDocument} html
 * @param  options orientation: landscape or portrait (default)
 margins: map of margin sizes
 top: number (default: 1440, i.e. 2.54 cm)
 right: number (default: 1440)
 bottom: number (default: 1440)
 left: number (default: 1440)
 header: number (default: 720)
 footer: number (default: 720)
 gutter: number (default: 0)
 * @return {Blob}
 */
const getWordBlobWithOptions = (html, options) => {
  return htmlDocx.asBlob(html, options);
};

export { getWordBlob, getWordBlobWithOptions };
