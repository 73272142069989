// A library to generate PDFs in JavaScript
// https://www.npmjs.com/package/jspdf
// https://artskydj.github.io/jsPDF/docs/jsPDF.html

import { jsPDF } from "jspdf/dist/jspdf.es.min.js"; // According to chatGpt, importing like this is a little lighter than importing whole jsPdf
import "jspdf-autotable";

/**
 * Gets JsPdf instance
 @return {*}
 */
const createJsPdf = () => {
  return new jsPDF();
};

/**
 * Generates a PDF table using the provided jsPDF instance and data.
 * @param {jsPDF} doc The jsPDF instance to use.
 * @param {string[]} tableData The data to use for the PDF table.
 * @param {string[]} headers The headers for the PDF table.
 * @param {string} date The date to use in the PDF header.
 */
const generatePdfTable = (doc, tableData, headers, date = undefined) => {
  // Define the footer text and font size
  const headerFontSize = 14;
  const headerFontStyle = "normal";
  const footerFontSize = 10;

  // Define the didDrawPage callback function
  const didDrawPage = function(data) {
    // Get the page width and height
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;

    // Check if it's the first page, add the header and footer
    if (data.pageNumber === 1) {
      // Set the font size for the header
      doc.setFontSize(headerFontSize);
      doc.setFont(undefined, headerFontStyle);

      // Add the header text to the top-center of the page
      doc.text(
        `Questys Detailed Report \n ${date}  `,
        pageWidth / 2,
        data.settings.margin.top - 3,
        { align: "center" }
      );
    }

    // Set the font size for the footer
    doc.setFontSize(footerFontSize);
    const pageInfo = doc.internal.getCurrentPageInfo();
    const footerText = "Page " + pageInfo.pageNumber;

    // Add the footer text to the bottom-right corner of the page
    doc.text(
      footerText,
      pageWidth - data.settings.margin.bottom,
      pageHeight - footerFontSize - 10
    );

    doc.setFontSize(footerFontSize);

    // Add the footer text to the bottom-center of the page
    doc.text(
      ` Powered By Questys `,
      pageWidth / 2,
      pageHeight - footerFontSize - 10,
      { align: "center" }
    );

    // Add a line to the bottom of the page
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.1);
  };

  const styles = {
    fontSize: 8
  };

  // Generate PDF table using autoTable plugin
  doc.autoTable({
    head: [headers],
    body: tableData,
    didDrawPage: didDrawPage,
    startY: 20,
    styles: styles,
    margin: { bottom: footerFontSize + 12 }
  });
};

export { createJsPdf, generatePdfTable };
